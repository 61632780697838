import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { pageview as YandexPageView } from 'components/YandexMetrika'

import 'semantic-ui-css/semantic.min.css'
import 'public/css/styles.css'

export default function MyApp({ Component, pageProps }) {
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = (url) => {
      YandexPageView()
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return <Component {...pageProps} />
}

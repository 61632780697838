import React from 'react'

const code = process.env.NEXT_PUBLIC_YANDEX_METRIKA_ID
const key = `yaCounter${code}`

const YandexMetrika = ({}) => {
  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  return (
    <>
      {' '}
      <script
        dangerouslySetInnerHTML={{
          __html: `
(function (m, e, t, r, i, k, a) {
m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) };
  m[i].l = 1 * new Date(); k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
})
  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

ym(${code}, "init", {
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true
});`,
        }}
      />
      <noscript>
        <div>
          <img
            src={`https://mc.yandex.ru/watch/${code}`}
            style={{ position: 'absolute', left: '-9999px' }}
            alt=""
          />
        </div>
      </noscript>
    </>
  )
}

export function pageview() {
  if (process.env.NODE_ENV !== 'production') {
    console.log(`Pageview triggered for ${window.location.pathname}`)
  }
  if (window[key]) {
    window[key].hit(window.location.pathname)
  }
}

export function reachGoal(...args) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('Goal reached:', ...args)
  }
  if (window[key]) {
    window[key].reachGoal(...args)
  }
}

export default YandexMetrika
